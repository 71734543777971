import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  DialogActions,
  Dialog,
  DialogContent,
  Button,
  Autocomplete,
  DialogTitle,
  TextField
} from '@mui/material';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useAnalyticsFilters } from '../../../../hooks/analyticsFilters';
import Loading from '../../../../atoms/Loading';
import { useCustomQuery } from '../../../../hooks/graphql';
import UserContext from '../../../../context';

const FILTER_PRODUCT = gql`
  query AttribTargetsModal(
    $from: String!
    $to: String!
    $filters: [AttributionPings_Filters]
    $timezone: String!
    $conversionMethodology: String!
    $clientId: ID!
    $ecommerceObjectIndex: Int
    $viewId: ID
  ) {
    datePickerFrom @client @export(as: "from")
    datePickerTo @client @export(as: "to")
    client(clientId: $clientId) {
      id
      attributionPings(filters: $filters, viewId: $viewId) {
        products(
          conversionMethodology: $conversionMethodology
          from: $from
          to: $to
          timezone: $timezone
          ecommerceObjectIndex: $ecommerceObjectIndex
        ) {
          list {
            id
            name
          }
        }
      }
    }
  }
`;

const ProductIdModal = ({
  handleClose,
  isOpen,
  viewFilters,
  selectedFilters,
  handleOnUpdate,
  activeProductObjectIndex
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState([]);
  const { filters } = useAnalyticsFilters();
  const { clientSettings } = useContext(UserContext);
  const activeProductObjectToUse =
    activeProductObjectIndex === 'null' ? null : activeProductObjectIndex;

  const { data } = useCustomQuery(FILTER_PRODUCT, {
    variables: {
      clientId: clientSettings.id,
      conversionMethodology:
        clientSettings.attributionPingsSettings.conversionMethodology,
      timezone: clientSettings.timezone,
      filters: [],
      ecommerceObjectIndex: activeProductObjectToUse
    },
    showLoader: false,
    skip: !isOpen || activeProductObjectToUse === undefined,
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (isOpen) {
      setValues(
        selectedFilters?.find(
          ({ type }) => type === `product_id-${activeProductObjectToUse}`
        )?.ids || []
      );
    }
  }, [
    selectedFilters,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(filters),
    isOpen,
    activeProductObjectToUse
  ]);

  const optionsList = useMemo(
    () =>
      data?.client?.attributionPings?.products?.list
        ?.filter(
          ({ id }) =>
            !viewFilters?.some(
              ({ type }) => type === `product_id-${activeProductObjectToUse}`
            ) ||
            viewFilters?.some(
              ({ type, ids }) =>
                type === `product_id-${activeProductObjectToUse}` &&
                ids.includes(id)
            )
        )
        .map(({ id }) => id),
    [data, viewFilters, activeProductObjectToUse]
  );

  return (
    <Dialog maxWidth="sm" fullWidth onClose={handleClose} open={isOpen}>
      <DialogTitle>
        {t('ma.filter.filterByProductName', {
          name: clientSettings?.attributionPingsSettings?.customAttributes?.find(
            ca =>
              ca.type === 'ecommerceObject' &&
              ca.ecommerceObjectIndex === activeProductObjectToUse
          )?.name
        })}
      </DialogTitle>

      <DialogContent>
        {!data ? (
          <Loading />
        ) : (
          <Autocomplete
            multiple
            value={values}
            onChange={(_, v) => setValues(v)}
            id="tags-standard"
            options={optionsList}
            getOptionLabel={option => {
              const productData = data?.client?.attributionPings?.products?.list.find(
                ({ id }) => id === option
              );
              return `${option} - ${productData.name}`;
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="standard"
                label=""
                placeholder={t('ma.filter.selectProductName')}
              />
            )}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('commun.cancel')}
        </Button>
        <Button
          disabled={values?.length <= 0}
          onClick={() => {
            handleOnUpdate(values);
            handleClose();
          }}
          color="primary"
        >
          {t('commun.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProductIdModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  activeProductObjectIndex: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  handleOnUpdate: PropTypes.func.isRequired,
  viewFilters: PropTypes.array.isRequired,
  selectedFilters: PropTypes.array.isRequired
};

export default ProductIdModal;
