import { useContext, useCallback, useMemo } from 'react';
import UserContext, { analyticsFiltersAtom } from '../context';
import { useParams } from 'react-router-dom';
import { useAtom } from 'jotai';

export const useAnalyticsHelpers = () => {
  let { channelId: parentChannelIdFromUrl } = useParams();
  const { clientSettings, selectedView } = useContext(UserContext);
  const [analyticsFilters] = useAtom(analyticsFiltersAtom);

  // Parent channel Id can come from a URL slug or from the analytics filters
  const parentChannelId = useMemo(() => {
    if (parentChannelIdFromUrl) {
      return parentChannelIdFromUrl;
    }

    if (
      analyticsFilters &&
      analyticsFilters?.some(({ type }) => type === 'channel') &&
      analyticsFilters.find(({ type }) => type === 'channel').ids.length === 1
    ) {
      return analyticsFilters.find(({ type }) => type === 'channel').ids[0];
    }
  }, [parentChannelIdFromUrl, analyticsFilters]);

  const hasAccessToOmnicanal = useMemo(() => {
    return clientSettings?.attributionPingsSettings?.connectors?.some(
      ({ type }) => type === 'advalo'
    );
  }, [clientSettings]);

  const hasBackOfficeImportActivated = useMemo(() => {
    return (
      clientSettings?.attributionPingsSettings?.backOfficeImports?.settings
        ?.file?.dateFormat &&
      clientSettings?.attributionPingsSettings?.backOfficeImports?.settings
        ?.file?.format &&
      clientSettings?.attributionPingsSettings?.backOfficeImports?.settings
        ?.file?.columnsMapping?.conversionId &&
      clientSettings?.attributionPingsSettings?.backOfficeImports?.settings
        ?.file?.columnsMapping?.conversionDate &&
      clientSettings?.attributionPingsSettings?.backOfficeImports?.settings
        ?.importDefinition?.length > 0
    );
  }, [clientSettings]);

  const hasGoogleSearchConsoleConnector = useMemo(
    () =>
      !!(
        parentChannelId &&
        clientSettings?.attributionPingsSettings?.connectors?.find(
          item =>
            item.type === 'seo' &&
            item.channels.some(channel => channel.channelId === parentChannelId)
        )
      ),
    [clientSettings?.attributionPingsSettings?.connectors, parentChannelId]
  );

  const hasAdsConnector = useCallback(
    providerId => () =>
      !!clientSettings?.attributionPingsSettings?.connectors?.find(
        item => item.type === 'ad' && item.provider === providerId
      ),
    [clientSettings?.attributionPingsSettings?.connectors]
  );

  const selectedViewData = useMemo(() => {
    if (!selectedView) {
      return null;
    }

    return clientSettings?.attributionViews?.find(
      ({ id }) => id === selectedView
    );
  }, [clientSettings, selectedView]);

  const findChannelName = useCallback(
    channelId => {
      let name = null;

      if (parentChannelId) {
        const parentChannelData = clientSettings.attributionPingsSettings.channels.find(
          ({ id }) => id === parentChannelId
        );
        if (
          parentChannelData?.subChannels?.some(({ id }) => id === channelId)
        ) {
          return parentChannelData?.subChannels?.find(
            ({ id }) => id === channelId
          ).name;
        }
      }

      name = clientSettings.attributionPingsSettings.channels.find(
        ({ id }) => id === channelId
      )?.name;

      if (!name) {
        for (const channel of clientSettings.attributionPingsSettings
          .channels) {
          if (!channel?.subChannels) {
            continue;
          }

          for (const subChannel of channel.subChannels) {
            if (subChannel.id === channelId) {
              name = subChannel.name;
            }
          }
        }
      }

      return name;
    },
    [clientSettings.attributionPingsSettings.channels, parentChannelId]
  );

  return {
    findChannelName,
    hasAccessToOmnicanal,
    selectedViewData,
    hasGoogleSearchConsoleConnector,
    hasBackOfficeImportActivated,
    hasAdsConnector
  };
};
