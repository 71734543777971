import {
  formatISO,
  format,
  subMonths,
  startOfMonth,
  lastDayOfMonth,
  formatDuration,
  differenceInDays,
  isToday
} from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-US';
import { formatInTimeZone } from 'date-fns-tz';
import i18next from 'i18next';

export const getStringDateFormat = languageCode =>
  languageCode === 'fr' ? 'dd/MM/yyyy' : 'yyyy-MM-dd';

export const getStringHourFormat = languageCode =>
  languageCode === 'fr' ? 'HH:mm' : 'h:mm aaa';

// Format a date to an ISO data as string: yyyy-MM-dd
export const formatDateToString = date =>
  formatISO(date || new Date(), {
    representation: 'date'
  });

export const formatCompleteDateToString = date =>
  formatISO(date || new Date(), {
    representation: 'complete'
  });

export const formatCompleteTimeToString = date =>
  formatISO(date || new Date(), {
    representation: 'time'
  });

export const formatDateToTextMonthAndYear = date =>
  format(date || new Date(), 'MMMM yyyy', {
    locale: i18next.resolvedLanguage === 'fr' ? frLocale : enLocale
  });

export const formatDateToTextDayAndMonthAndYear = date =>
  format(date || new Date(), 'dd MMMM yyyy', {
    locale: i18next.resolvedLanguage === 'fr' ? frLocale : enLocale
  });

// Format a date to text month in french and year: MMMM yyyy
export const formatToDuration = secondsItem => {
  const days = Math.floor((secondsItem % 31536000) / 86400);
  const hours = Math.floor(((secondsItem % 31536000) % 86400) / 3600);
  const minutes = Math.floor((((secondsItem % 31536000) % 86400) % 3600) / 60);
  const seconds = (((secondsItem % 31536000) % 86400) % 3600) % 60;

  return formatDuration(
    { days, hours, minutes, seconds },
    {
      locale: i18next.resolvedLanguage === 'fr' ? frLocale : enLocale,
      format: ['days', 'hours', 'minutes']
    }
  );
};

// Format a date to short french date: dd/MM
export const formatDateToShortDate = (date, timezone = null) => {
  if (!timezone) {
    return format(
      date || new Date(),
      i18next.resolvedLanguage === 'fr' ? 'dd/MM' : 'MM-dd'
    );
  } else {
    return formatInTimeZone(
      date || new Date(),
      timezone,
      i18next.resolvedLanguage === 'fr' ? 'dd/MM' : 'MM-dd'
    );
  }
};

// Format a date to short french date: dd/MM/yyyy
export const formatDateToLargeDate = (date, timezone = null) => {
  if (!timezone) {
    return format(
      date || new Date(),
      i18next.resolvedLanguage === 'fr' ? 'dd/MM/yyyy' : 'yyyy-MM-dd'
    );
  } else {
    return formatInTimeZone(
      date || new Date(),
      timezone,
      i18next.resolvedLanguage === 'fr' ? 'dd/MM/yyyy' : 'yyyy-MM-dd'
    );
  }
};

// Format a date and time to complete french date
export const formatDateAndTimeToLargeDate = (date, timezone = null) => {
  if (!timezone) {
    return format(
      date || new Date(),
      i18next.resolvedLanguage === 'fr'
        ? "dd/MM/yyyy 'à' HH:mm"
        : "yyyy-MM-dd 'at' h:mm aaa"
    );
  } else {
    return formatInTimeZone(
      date || new Date(),
      timezone,
      i18next.resolvedLanguage === 'fr'
        ? "dd/MM/yyyy 'à' HH:mm"
        : "yyyy-MM-dd 'at' h:mm aaa"
    );
  }
};

export const formatDateAndCompareTimeLargeDate = (
  startDate,
  endDate,
  timezone = null
) => {
  if (!timezone) {
    const startDateFormatted = format(
      startDate || new Date(),
      i18next.resolvedLanguage === 'fr'
        ? "dd/MM/yyyy 'de' HH:mm"
        : "yyyy-MM-dd 'from' h:mm aaa"
    );

    const endDateFormatted = format(
      endDate || new Date(),
      i18next.resolvedLanguage === 'fr' ? "'à' HH:mm" : "'to' h:mm aaa"
    );

    return `${startDateFormatted} ${endDateFormatted}`;
  } else {
    const startDateFormatted = formatInTimeZone(
      startDate || new Date(),
      timezone,
      i18next.resolvedLanguage === 'fr'
        ? "dd/MM/yyyy 'de' HH:mm"
        : "yyyy-MM-dd 'from' h:mm aaa"
    );

    const endDateFormatted = formatInTimeZone(
      endDate || new Date(),
      timezone,
      i18next.resolvedLanguage === 'fr' ? "'à' HH:mm" : "'to' h:mm aaa"
    );

    return `${startDateFormatted} ${endDateFormatted}`;
  }
};

export const formatDateAndTimeToExtraLargeDate = date =>
  format(
    date || new Date(),
    i18next.resolvedLanguage === 'fr'
      ? "dd/MM/yyyy 'à' HH:mm:ss"
      : "yyyy-MM-dd 'at' h:mm:ss aaa"
  );

export const formatDateToTimeLarge = date =>
  format(
    date || new Date(),
    i18next.resolvedLanguage === 'fr' ? 'HH:mm:ss' : 'h:mm:ss aaa'
  );

// Return the date of first day of the previous month
export const firstDayOfPreviousMonth = () =>
  startOfMonth(subMonths(new Date(), 1));

// Return the date of last day of the previous month
export const lastDayOfPreviousMonth = () =>
  lastDayOfMonth(subMonths(new Date(), 1));

export const getPollingInterval = (from, to) => {
  if (!isToday(new Date(to))) {
    return 0;
  }
  const days = differenceInDays(new Date(to), new Date(from));

  if (days <= 90) {
    return 300000;
  }
  return 0;
};

export const getFetchPolicy = to => {
  if (!isToday(new Date(to))) {
    return 'cache-first';
  }

  return 'no-cache';
};
